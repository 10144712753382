<template>
    <div>
        <h1 class="mb-4 mt-3 text-center">
            Log In
        </h1>

        <b-form @submit.prevent="tryToLogIn">
            <b-form-group label="Email" label-for="email">
                <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    required
                    placeholder="Enter email"
                />
            </b-form-group>

            <b-form-group label="Password" label-for="password">
                <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    required
                    placeholder="Enter password"
                />
            </b-form-group>

            <b-alert v-model="isAuthError" variant="danger" dismissible>
                Wrong credentials!
            </b-alert>

            <b-form-group class="mt-4">
                <b-button type="submit" class="btn-block">
                    Log In
                </b-button>
            </b-form-group>

            <div class="row mt-3">
                <div class="col-12 text-center">
                    <router-link to="/forget-password">
                        Forgot your password?
                    </router-link>
                </div>
            </div>
        </b-form>

        <p class="text-center mt-2">
            Don't you have account?
            <router-link to="/sign-up">
                Sign Up
            </router-link>
        </p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { StatusCodes } from 'http-status-codes';

export default {
    page: {
        title: 'Login'
    },

    data() {
        return {
            email: '',
            password: '',
            isAuthError: false,
            tryingToLogIn: false
        };
    },

    methods: {
        ...mapActions({
            login: 'auth/login'
        }),

        async tryToLogIn() {
            this.tryingToLogIn = true;
            this.authError = null;

            try {
                await this.login({
                    email: this.email,
                    password: this.password
                });

                this.tryingToLogIn = false;
                this.isAuthError = false;

                const { redirectFrom = '/' } = this.$route.query;

                const redirectRoute = !['/logout', '/login'].includes(
                    redirectFrom
                )
                    ? redirectFrom
                    : { name: 'dashboard' };

                this.$router.push(redirectRoute);
            } catch (error) {
                console.error(error);

                Sentry.captureException(error);

                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    this.isAuthError = true;

                    return;
                }

                this.$toasterError();
            }
        }
    }
};
</script>
